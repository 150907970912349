
<template>
  <div>
    <div v-if="show_render_area" class="body">
      <div id="loading-overlay">
        <div id="loading-bar">
          <div id="progress"></div>
        </div>
      </div>

      <div class="connection_warning_panel" id="connection_warning">
        Slow network connection detected: this may impact performance.
      </div>

      <div class="floating_panel_top_left">
        <div class="h3">{{visualizer_case_id}}</div><br>
        Minimum distance from skin to tumor: <span class="important">{{visualizer_distance_tumor_to_breast}} mm</span> (<span class="line_breast_to_tumor">+</span>)<br>
        Minimum distance from tumor to chest wall: <span class="important">{{visualizer_distance_tumor_to_chest_wall}} mm</span> (<span class="line_tumor_to_chest_wall">+</span>)<br>
        Tumor margin size: <span class="important">{{visualizer_tumor_margin_size}} mm</span><br>
        Tumor dimensions:<br>
        &emsp;medial to lateral <span class="important">{{visualizer_tumor_extents0}} cm</span><br>
        &emsp;superior to inferior <span class="important">{{visualizer_tumor_extents1}} cm</span><br>
        &emsp;superficial to deep <span class="important">{{visualizer_tumor_extents2}} cm</span><br>
        Excision specimen dimensions:<br>
        &emsp;medial to lateral <span class="important">{{visualizer_tumor_margin_extents0}} cm</span><br>
        &emsp;superior to inferior <span class="important">{{visualizer_tumor_margin_extents1}} cm</span><br>
        &emsp;superficial to deep <span class="important">{{visualizer_tumor_margin_extents2}} cm</span><br>
        Targeted excision specimen volume: <span class="important">{{visualizer_optimal_excision_volume}} mL</span><br>
        Wire localization needle length: <span class="important">{{visualizer_needle_length}}</span><br>
        <div id="clinical_notes"></div>
      </div>

      <div class="floating_panel_top_right">
        <a href="https://cairn-st-p-info.s3.eu-north-1.amazonaws.com/ROOT/video/bcl_training_video.html" target="_blank"><button type="button" class="bn">Training Video</button></a>
        <button type="button" class="bn" v-on:click="visualizer_reset_view()">Reset View</button>
      </div>

    </div>
    <div>
      <canvas id="three_canvas"></canvas>
    </div>

  </div>
</template>

<script>

import {c_visualizer} from "../utils/c_visualizer";

const python_api = process.env.VUE_APP_VISUALIZER_PYTHON_API;
const VISUALIZER_PYTHON_API = python_api ? python_api : "http://localhost:5005/api/v1";
const USE_SERVER = process.env.VUE_APP_VISUALIZER_USE_SERVER ? true : false;
let axios = null;

if (USE_SERVER) {
  axios = require('axios');
}

export default {
  name: "VisualizerComponent",
  data() {
    return {
      visualizer_case_id: "",
      visualizer_distance_tumor_to_chest_wall: null,
      visualizer_distance_tumor_to_breast: null,
      visualizer_tumor_extents0: null,
      visualizer_tumor_extents1: null,
      visualizer_tumor_extents2: null,
      visualizer_tumor_margin_extents0: null,
      visualizer_tumor_margin_extents1: null,
      visualizer_tumor_margin_extents2: null,
      visualizer_needle_length: null,
      visualizer_optimal_excision_volume: null,
      visualizer_tumor_margin_size: null,
      connection_warning: false,
      show_render_area: false,
      use_server: false, 
    }
  },
  async created() {
      this.use_server = USE_SERVER;

      if (!this.use_server) {
        this.show_render_area = true;
      }
  },
  async mounted() {

    if (this.use_server) {
      await this.use_visualizer_server();
    }
    else {
      await this.use_local_files();
    }
  },
  methods: 
  {
    async use_local_files() {
      const base_url = "data/visualizer_Test2";
      await this.init_three(base_url, null);
    },
    async use_visualizer_server() {
      const uri = window.location.href.split('?');
      if (uri && uri.length > 1) {
        const queryParams = uri[1];
        const pairs = queryParams.split("&")

        let manifestVal = null;

        for (let currentPair of pairs) {
          const PARAM_MANIFEST = "manifest="

          if (currentPair.startsWith(PARAM_MANIFEST)) {

            this.show_render_area = true;
            let queryTokens = currentPair.split(PARAM_MANIFEST);

            if (queryTokens.length && queryTokens[0] === "") {
              manifestVal = queryTokens[1];
            }
          }
        } // end loop on pairs

        // clear query params
        window.history.replaceState({}, document.title, "/");

        if (manifestVal) {
          let inputParameters = { params: { manifest: manifestVal }};

          let response = await axios.get(`${VISUALIZER_PYTHON_API}/launch`, inputParameters);
          const visualizer_info = response.data.visualizer_info;

          if (visualizer_info) {
            await this.init_three(null, visualizer_info);
          }
          else {
            this.show_render_area = false;
          }
        }
      }
    },
    async init_three(base_url, visualizer_info) 
    {
      // https://stackoverflow.com/questions/65693108/threejs-component-working-in-vuejs-2-but-not-3
      this.rendering_viewport = new c_visualizer();
      const return_info = await this.rendering_viewport.initialize(base_url, visualizer_info); 

      if (return_info.success) 
      {
        const metadata_info = return_info.metadata_info;
        this.visualizer_case_id = metadata_info.case_id;
        this.visualizer_distance_tumor_to_chest_wall = metadata_info.distance_tumor_to_chest_wall;
        this.visualizer_distance_tumor_to_breast = metadata_info.distance_tumor_to_breast;
        this.visualizer_tumor_extents0 = metadata_info.tumor_extents0;
        this.visualizer_tumor_extents1 = metadata_info.tumor_extents1;
        this.visualizer_tumor_extents2 = metadata_info.tumor_extents2;
        this.visualizer_tumor_margin_extents0 = metadata_info.tumor_margin_extents0;
        this.visualizer_tumor_margin_extents1 = metadata_info.tumor_margin_extents1;
        this.visualizer_tumor_margin_extents2 = metadata_info.tumor_margin_extents2;
        this.visualizer_needle_length = metadata_info.needle_length;
        this.visualizer_optimal_excision_volume = metadata_info.optimal_excision_volume;
        this.visualizer_tumor_margin_size = metadata_info.tumor_margin_size;
      }
      else
      {
        const metadata_info = return_info.metadata_info;
        this.visualizer_case_id = metadata_info.case_id;
      }
    },
    visualizer_reset_view() 
    {
      this.rendering_viewport.reset_view();
    },
  }
}
</script>

<style>
@import '../css/style.css';
</style>
